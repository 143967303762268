.footer-container {
    background-color: #078bbf;
    padding: 50px 0px;
}

.footer-container h2 {
    color: white;
    font-family: 'DM Serif Display', serif;
    font-size: 30px;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 30px;
}
.locations-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.location {
    width: 33.33%;
    text-align: center;
}
.location p {
    color: white;
    font-family: 'DM Serif Display', serif;
    font-size: 15px;
}
.location h4 {
    color: white;
    font-family: 'DM Serif Display', serif; 
    text-align: center;
    font-size: 20px
}

.subfooter-container {
    background-color: #078bbf;
    text-align: center;
    /* height: 30px; */
    padding: 5px 0px;
}
.subfooter-ul {
    list-style-type: none;
}

.subfooter-ul li {
    display:inline-block;
    color: white;
    padding: 0px 15px;
    font-family: 'DM Serif Display', serif;
}


@media (max-width:600px){
    .locations-container {
        flex-direction: column;
        width: 100%;
       }
       .location {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
       }
}