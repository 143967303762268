.why-servevision-section {
    /* height: 100vh; */
    margin-top: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    user-select: none;
}
.why-servevision-section h2 {
    text-align: center;
    font-size: 40px !important;
    text-transform: capitalize;
}
.main-service-heading {
    text-align: center;
    font-size: 40px;
    padding-top: 50px;
}
.why-servevision-section p {
    text-align: left;
}