.top-clients-work {
    background: white;
    /* height: 70vh; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding: 30px 0px;
  }
  .top-clients-work h2 {
    font-size: 45px;
    text-transform: capitalize;
    font-family: 'DM Serif Display', serif;
    font-size: 46px;   
}
.logos-section {
    display: flex;
    width: 60%;
    margin-left: auto;
    margin-right: auto ;
    justify-content: space-between;
    margin-top: 50px;
}
.logos-section img {
    width: 150px;
    height: auto;
}

@media (max-width:600px){
    .top-clients-work h2 {
        font-size: 40px;
    }
    .logos-section {
        flex-direction: column;
        width: 80%;
        align-items: center;
        margin-top: 0px;
    }
    .logos-section img {
        margin-top: 50px;
    }
}