/* Service Section */
.service-section-2 {
    background:white;
    background-size: cover;
    padding-bottom: 50px;
    background-position: left;
    padding: 40px 0px;
}
.services-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}
.service-row-one {
    margin-top: 2%
}
.services {
    width: calc(33.33% - 20px);
    text-align: center;
    padding: 10px 50px;
    /* border: 1px solid black; */
    transition: all 1s ease;
    cursor: pointer;
    border-radius: 10px;
    background-color: #EAFDFC;
}
.services:hover {
    box-shadow: 0px 0px 30px #acd8ff    ;
}
.services img {
    width: 90px
}
.services h2 {
    font-size: 28px;
    font-weight: bold;
    font-family: 'DM Serif Display', serif;
}
.service-section-2 .main-service-heading {
    text-align: center;
    text-transform: capitalize;
    font-family: 'DM Serif Display', serif;
    font-size: 46px;
}
.main-service-heading {
    text-align: center;
    font-family: 'DM Serif Display', serif;
    font-size: 46px;
}
.services p {
    font-size: 15px;
    /* text-align: justify; */
}

@media (max-width:600px){
    .services-container {
        flex-direction: column;
        /* width: 1%; */
    }
    .services {
        width: 100%;
        /* max-width: 80%; */
        margin-top: 20px;
    }
    .service-section-2 .main-service-heading {
        font-size: 40px;
    }
}