.video-main {
    width: 100%;
    height: calc(100vh - 1px);
.mobile-video {
    display: none;
}
video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.content {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    h2 {
        color: #005171;
        font-size: 2.5rem;
        text-transform: capitalize;
        padding-bottom: 20px;
        font-weight: 700;
    }
    .need-service {
        color: #0090c8;
        padding-bottom: 20px;
        text-align: right;
    }
    .image-button-section {
        text-align: right;
        .image-button {
            text-align: center;
            width: 12rem;
            height: 3rem;
            border-radius: 1rem;
            box-shadow:0.3rem 0.3rem 0.6rem #c8d0e7, -0.2rem -0.2rem 0.5rem #FFFFFF;
            cursor: pointer;
            transition: 0.3s ease;
            background: #6d5dfc;
            color: #E4EBF5;
            font-size: 25px;
            user-select: none;
        }
    }
}
}


@media only screen and (max-width:600px) {
    .video-main {
        .laptop-video{
            display: none;
        }
        .mobile-video {
            display: block;
        }
        .content {
            width: 100%;
            align-items: center;
        h2 {
            font-size: 1.6rem;
            text-align: center;
            margin-top: 30px;
        }
        .image-button-section {
            margin-top: 90%;
        }
        }
    }
}