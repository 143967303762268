.website-logo {
    width: 250px;
}

.nav-link {
    font-weight: 700 !important;
    color: black !important;
}
.navbar-text {
    font-weight: 700 !important;
    color: black !important;
    font-size: 18px;
    a {
        color: blue !important;
    }
}