.testimonials-section {
    background: url('../../assets/testimonialsbackground.jpg');
    background-size: cover;
    /* height: 100vh; */
    padding: 50px 0px;
    user-select: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
figure {
    display: none;
}
.testimonials-section .main-service-heading {
    text-transform: capitalize;
    font-family: 'DM Serif Display', serif;
    font-size: 46px;
}
.testimonial-main-section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
}
.testimonials {
    width: 33.33%;
}
/* Testing Tesimonials */
article {
    /* limit the width of the article container */
    width: 300px;
    /* display the contents in a column */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: hsl(0, 0%, 100%);
    line-height: 2;
    border-radius: 10px;
    margin: 0.5rem;
    /* transition for the transform property, updated in the script */
    transition: transform 0.2s ease-out;
    box-shadow: 0 0 5px -2px hsla(0, 0%, 0%, 0.1);
  }
  article figure {
    /* limit the width and height of the figure to show the image in a circle */
    width: 120px;
    height: 120px;
    border-radius: 50%;
    /* specify negative margin matching half the height of the element */
    margin-top: -60px;
    /* position relative for the pseudo element */
    position: relative;
    
  }
  article figure:before {
    /* add a border around the figure matching the color of the background, faking the clip */
    content: "";
    border-radius: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    border: 1rem solid hsl(250, 85%, 97%);
    box-shadow: 0 1px hsla(0, 0%, 0%, 0.1);
  }
  article figure img {
    /* stretch the image to cover the size of the wrapping container */
    border-radius: inherit;
    width: 100%;
    height: 100%;
    /* object fit to maintain the aspect ratio and fit the width/height */
    object-fit: cover;
  }
  
  article div {
    /* center the text in the div container */
    text-align: center;
    margin: 2rem;
  }
  article div p {
    color: hsl(250, 5%, 45%);
    font-weight: 400;
    font-style: italic;
    margin: 1rem 0 3rem;
    font-family: "Noticia Text", serif;
    /* position relative for the pseudo element */
    position: relative;
    z-index: 5;
  }
  article div p:before {
    /* with SVG elements include two icons for the quote */
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: -5;
    opacity: 0.05;
    /* position the icons at either end of the paragraph, rotate the second to have a mirrorer image */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" width="70" height="70"><rect x="0" y="40" width="30" height="30"></rect><path d="M 0 40 q 0 -40 30 -40 v 15 q -15 0 -15 25"></path><rect x="40" y="40" width="30" height="30"></rect><path d="M 40 40 q 0 -40 30 -40 v 15 q -15 0 -15 25"></path></svg>'),
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" width="70" height="70" transform="rotate(180)"><rect x="0" y="40" width="30" height="30"></rect><path d="M 0 40 q 0 -40 30 -40 v 15 q -15 0 -15 25"></path><rect x="40" y="40" width="30" height="30"></rect><path d="M 40 40 q 0 -40 30 -40 v 15 q -15 0 -15 25"></path></svg>');
    background-position: 20% 20%, 80% 80%;
    background-repeat: no-repeat;
  }
  article div h1 {
    /* considerably reduce the size of the heading */
    color: hsl(260, 5%, 55%);
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  @media (max-width:600px){
    /* Testimonail Section */
    .testimonials-section {
        height: auto;
        background: url('../../assets/testimonialsbackgroundmobile.jpg');
        background-size: cover;
    }
    .testimonials-section h2 {
        font-family: 'DM Serif Display', serif;
        font-size: 46px;
        text-transform: capitalize;
    }
    .testimonial-main-section {
        flex-direction: column;
        width: 90%;
        align-items: flex-start;
    }
    .testimonial-two, .testimonial-three {
        margin-top: 60px;
    }
  }