.case-studies {
    background: white;
    user-select: none;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    user-select: none;
}

.case-studies-container {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
}
.case-study-one {
    width: 25%;
    cursor: pointer;
}
.poster-main-container {
	width: 270px;
	height: 540px;
	position: relative;
	margin: 0 20px;
}

.poster-container {
	width: 230px;
	position: absolute;
	top: 0;
	left: 20px;
	z-index: 9999;
}

.poster {
	width: 100%;
	box-shadow: 0 5px 20px 3px rgba(0, 0, 0, 0.6);
}
.ticket__movie-slogan {
    padding: 10px;
}
.ticket-container {
	background: #fff;
	width: 270px;
	height: 460px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
	position: absolute;
	top: 20px;
	box-shadow: 0 5px 20px 3px rgba(0, 0, 0, 0.6);
	opacity: 1;
}

.poster-main-container:hover .ticket-container {
	opacity: 1;
	animation: bounceIn 0.6s linear;
}

@keyframes bounceIn {
	0%,
	20%,
	40%,
	60%,
	80%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	20% {
		-webkit-transform: scale3d(1.03, 1.03, 1.03);
		transform: scale3d(1.03, 1.03, 1.03);
	}
	40% {
		-webkit-transform: scale3d(0.9, 0.9, 0.9);
		transform: scale3d(0.9, 0.9, 0.9);
	}
	60% {
		opacity: 1;
		-webkit-transform: scale3d(1.01, 1.01, 1.01);
		transform: scale3d(1.01, 1.01, 1.01);
	}
	80% {
		-webkit-transform: scale3d(0.97, 0.97, 0.97);
		transform: scale3d(0.97, 0.97, 0.97);
	}
	to {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

/* @keyframes bounce {
  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
} */

.ticket__content {
	width: 100%;
	position: absolute;
	bottom: 0;
	text-align: center;
}

.ticket__movie-title {
	text-transform: uppercase;
	margin-bottom: 5px;
    padding-top: 50%;
}

.ticket__movie-slogan {
	color: #999;
	font-size: 0.9rem;
	margin-bottom: 20px;
}

.ticket__current-price {
	color: #69c982;
	font-size: 1.4rem;
	font-weight: bold;
}


.ticket__old-price {
	color: #999;
	text-decoration: line-through;
	margin-bottom: 10px;
}

.ticket__buy-btn {
	cursor: pointer;
	width: 100%;
	background: #2f2f2f;
	color: white;
	padding: 15px 0;
	font-size: 1rem;
	font-weight: bold;
	text-transform: uppercase;
	border: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

@media (max-width:600px){
    .main-after-case {
        flex-direction: column;
    }
    .after-case-image {
        width: 100%;
    }
    .after-case-text {
        width: 100%;
    }
    .after-case-image img {
        width: 100%;
    }
    .after-case-text h4 {
        margin-top: 15px;
        text-align: justify;
        text-transform: capitalize;
        align-items: center;
        display: flex;
    }
    .after-case-text h2 {
        font-size: 40px;
      }
      .why-icons-section {
        padding: 0;
      }
      .why-icons-section i {
        padding-top: 10px;
      }
      .why-choose-para {
        font-size: 15px !important;
        line-height: 1.5rem;
      }
}

@media (max-width:600px){
	.main-service-heading {
        font-size: 40px !important;
    }
    .case-studies-container {
        flex-direction: column;
    }
}