.consultation-section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 30vh;
    user-select: none;
    text-align: center;
}
.consultation-section h4 {
    text-align: center;
    font-size: 40px;
}
.consult-button {
    background-color: greenyellow;
    border: 0;
    border-radius: 20px;
    font-size: 25px;
    padding: 7px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
    color: rgb(0, 4, 128);
    cursor: pointer;
}

@media (max-width:600px){
    .consultation-section {
        height: auto;
    }
    .consultation-section h4 {
        font-size: 30px;
    }
}